var site = site || {};

(function ($) {
  window.site = site || {};
  site.drawers = site.drawers || {};

  site.drawers.$formatters = $();
  site.drawers.$closeButtons = $();

  site.drawers.animationSpeed = 300;
  site.drawers.mouseOutDelay = 800;
  site.drawers.drawerOpen = false;
  site.drawers.waited = false;
  site.drawers.waiting = false;
  site.drawers.isOver = false;
  site.drawers.keepOpen = false;
  site.drawers.disableStickyFooterMobile = site.drawers.disableStickyFooterMobile || false;

  var initialized = false;

  // Drawers functionality
  site.drawers.init = function (context) {
    // Only run once
    if (initialized) {
      return;
    }
    initialized = true;

    var $footer = $('.site-footer', context);
    var disableStickyMobile = site.client.isMobile && site.drawers.disableStickyFooterMobile;
    var $stickyFooter = $('.site-footer--sticky', context);
    var offset = 0;
    var stickyFooterHeight = $stickyFooter.height();
    var $liveChatHitArea = $('.js-product-page-livechat-hitarea', context);

    if (!$footer.length) {
      return;
    }

    function _setOffset() {
      var stickyClass = 'is-sticky';

      if (disableStickyMobile) {
        $stickyFooter.removeClass(stickyClass);
      } else {
        offset = $footer.offset().top - $(window).height() + stickyFooterHeight;
        $stickyFooter.toggleClass(stickyClass, $(window).scrollTop() < offset);
      }
    }

    var footerSetOffSet = _.debounce(_setOffset, 300);

    $(window).scroll(footerSetOffSet);
    // Use a timeout to ensure this is run after other scripts set their dimensions
    setTimeout(_setOffset, 60);

    site.drawers.$formatters = $('.drawer-formatter.js-mac-select-link', context);
    site.drawers.$container = $('.drawer-container', context);
    site.drawers.$closeButtons = $('.drawer-formatter__close', site.drawers.$container);

    function _mouseMove(event, over) {
      site.drawers.isOver = over === true ? true : false;
      // Don't bother with any of this if the drawer is closed
      if (!site.drawers.drawerOpen || site.drawers.keepOpen) {
        return;
      }

      if (event) {
        site.drawers.isOver = !!$(event.target).closest('.drawer-container').length;
      }

      if (!site.drawers.waiting && !site.drawers.waited) {
        // Set a delay before we check the cursor again
        site.drawers.waiting = true;
        setTimeout(function () {
          site.drawers.waited = true;
          site.drawers.waiting = false;
          _mouseMove(false, site.drawers.isOver);
        }, site.drawers.mouseOutDelay);
      } else if (site.drawers.waited) {
        if (!site.drawers.isOver) { // If we're still not over the container, close it
          _close();
        }
        site.drawers.waited = false;
      }
    }

    function _close(event) {
      if (typeof event != 'undefined') {
        event.preventDefault();
      }
      site.drawers.close();
      $('.js-mac-select-loyalty').attr('tabindex', '-1');
    }

    site.drawers.$formatters.each(function () {
      var $formatter = $(this);
      var $pane = $('.drawer-formatter__content', site.drawers.$container).filter(function () {
        return !($(this).css('visibility') == 'hidden' || $(this).css('display') == 'none' || $(this).css('opacity') == 0);
      });
      var $trigger = $('.js-sticky-footer-pc-links', $formatter);
      var hidePane = $pane.is(':hidden');
      var paneHeight = $pane.show().height();
      var $macSelectLoyaltyFooterFormatter = $('.js-mac-select-loyalty-footer-formatter', context);

      $pane.data('paneHeight', paneHeight);

      function _mouseOver(event) {
        var $macSelectLoyalty = $('.js-mac-select-loyalty');

        event.preventDefault();
        var speed = site.drawers.drawerOpen ? 0 : site.drawers.animationSpeed;

        site.drawers.open($(this), $pane, speed);
        $macSelectLoyalty.attr('tabindex', '0').focus();
        site.restrict_navigation($macSelectLoyalty);
      }
      if ($macSelectLoyaltyFooterFormatter.length > 0 && $macSelectLoyaltyFooterFormatter.data('hide-hitarea') === 1) {
        $liveChatHitArea.hide();
      }

      if ($macSelectLoyaltyFooterFormatter.length > 0 && $macSelectLoyaltyFooterFormatter.data('show-on-click') === 1) {
        $trigger.once().on('click', _mouseOver);
      } else {
        $trigger.once().on('mouseover', _mouseOver);
      }

      $trigger.on('focusout', function () {
        $('.js-sticky-footer-pc-links.custom-outline').removeClass('custom-outline');
      });
      if (hidePane) {
        $pane.hide();
      } else {
        site.drawers.drawerOpen = true;
      }
    });

    $('body', context).on('mousemove', _mouseMove);
    site.drawers.$closeButtons.on('click', _close);
    $('.js-mac-select-loyalty-slider-icon').on('keydown', function (e) {
      if (site.getKeycode(e) === 13) {
        e.preventDefault();
        $('.js-sticky-footer-pc-links.active').focus().addClass('custom-outline');
        _close();
      }
    });
  };

  // Drawers Open
  site.drawers.open = function ($trigger, $pane, speed, keepOpen) {
    if (site.drawers.drawerOpen) {
      $pane.siblings('.drawer-formatter__content').hide().css('top', 0);
    }
    site.drawers.drawerOpen = true;
    $pane.stop().show().data('paneHeight', $pane.height() + $trigger.height() + 20);
    $pane.stop().show().animate({
      top: -$pane.data('paneHeight')
    }, speed);
    $('.js-sticky-footer-pc-links', site.drawers.$container).removeClass('active');
    $trigger.addClass('active');
    this.keepOpen = !!keepOpen;
    site.drawers.lastPaneOpen = $pane;
    site.drawers.lastSpeed = speed;
  };

  // Drawers Close
  site.drawers.close = function ($pane) {
    if (typeof $pane == 'undefined') {
      $pane = $('.drawer-formatter__content:visible');
    }
    if (!$pane.is(':visible')) {
      return;
    }
    site.drawers.drawerOpen = false;
    site.drawers.keepOpen = false;
    $pane.stop().animate({
      top: 0
    }, site.drawers.animationSpeed, function () {
      $pane.hide();
    });
    $('.js-sticky-footer-pc-links').removeClass('active');
  };

  /**
   * Generic behaviors for footer drawers
   */
  Drupal.behaviors.stickyFooterV1 = {
    attach: function (context) {
      // Live chat Trigger
      var $liveChatMob = $('.js-mac-live-chat-mob', context);
      var disableBar;

      $('.js-mac-live-chat').bind('click', function (e) {
        e.preventDefault();
        $('#product-page-livechat-hitarea div').click();
        if ($('a.footer-liveperson-link', context).length > 0) {
          $('a.footer-liveperson-link', context)[0].click();
        }
      });

      $('.mac-select-chat').bind('click', function (e) {
        e.preventDefault();
        if ($('a.footer-liveperson-link').length > 0) {
          $('a.footer-liveperson-link')[0].click();
        }
      });

      $liveChatMob.once().on('click', function (e) {
        e.preventDefault();
        var $footerLivePersonMobile = $('body').find('a.footer-liveperson-link#home_page_mac_us_mobile');

        if ($footerLivePersonMobile.length > 0) {
          $footerLivePersonMobile.click();
        }
      });
      $('.js-sticky-footer-lp-chat').on('click', function (e) {
        e.preventDefault();
        var $lpmTrigger = $('.LPMcontainer img');

        if ($lpmTrigger.length) {
          $lpmTrigger.trigger('click');
        }
      });

      var isLoyalty = parseInt(site.userInfoCookie.getValue('is_loyalty_member'));
      var loyaltyLevel = parseInt(site.userInfoCookie.getValue('loyalty_level'));
      var isPro = parseInt(site.userInfoCookie.getValue('is_pro'));
      var $loyaltyUser = $('.js-loyalty-tier-' + loyaltyLevel, context);
      var $normalUser = $('.js-non-loyalty', context);
      var $proUser = $('.js-loyalty-tier-pro', context);
      var $macSelectLink = $('.js-mac-select-link', context);

      $('.mac-select-loyalty-tier', context).addClass('hide');
      $proUser.addClass('hide');
      // Content hide and show for Loyalty users and Normal Users
      if (isLoyalty || isPro) {
        if (isLoyalty && $loyaltyUser.length > 0) {
          $loyaltyUser.removeClass('hide');
        } else if (isPro && $proUser.length > 0) {
          $proUser.removeClass('hide');
        } else {
          $macSelectLink.addClass('hidden');
        }
        $('.js-hide-for-loyalty-user', context).addClass('hidden');
      } else if ($normalUser.length > 0) {
        $normalUser.removeClass('hide');
        $('.js-hide-for-normal-user', context).addClass('hidden');
      } else {
        $macSelectLink.addClass('hidden');
      }

      var mobileLinks = $('.mac-select-loyalty-footer-formatter .disable-pc:visible').length;
      var disableLargeElements = $('.mac-select-loyalty-footer-formatter .disable-pc');
      var disableSmallElements = $('.mac-select-loyalty-footer-formatter .disable-mobile');

      disableBar = $('.mac-select-loyalty-footer-formatter .mac-select-loyalty-footer-links__right', context);

      disableLargeElements.addClass('col-md-' + mobileLinks).first().addClass('no-bar');
      disableSmallElements.first().addClass('no-bar');
      disableBar.last().addClass('no-bar');
      site.drawers.init(context);


      const urlParams = new URLSearchParams(window.location.search);
      const hideGNavParam = urlParams.get(Drupal.settings.hideGNav.param);
      const pathName = window.location.pathname;
      const hideGNavPath = Drupal.settings.hideGNav.path;
      var $loyaltyFooter = $('.mac-select-loyalty-footer-formatter');

      if( hideGNavParam && pathName.includes(hideGNavPath)){
        $loyaltyFooter.addClass('hidden');
      } else {
        $loyaltyFooter.removeClass('hidden');
      }

      // check cookie
      if ($('.mac-select-loyalty-slider').data('footer_loyalty_popover') && !$.cookie('MacSelectLoyalty') && !site.client.isMobile) {
        site.drawers.drawerOpen = false;
        $.cookie('MacSelectLoyalty', 1, {
          expires: 3,
          path: '/'
        });
        var $macSelectLoyalty = $('.js-mac-select-loyalty.drawer-formatter__content');
        var $footer_loyalty_duration = $('.mac-select-loyalty-slider').data('footer_loyalty_duration') ? $('.mac-select-loyalty-slider').data('footer_loyalty_duration') : 10;

        if ($macSelectLoyalty.length) {
          setTimeout(function () {
            site.drawers.open($('.js-sticky-footer-pc-links.drawer-formatter__trigger'), $macSelectLoyalty, site.drawers.animationSpeed, true);
            setTimeout(function () {
              site.drawers.close($macSelectLoyalty);
            }, $footer_loyalty_duration * 1000); // site.specialOffers.footerDisplayDuration = 10
          }, 60);
        }
      }
    }
  };
})(jQuery);
